import { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { Button } from "../Button";
import { ArrowUpIcon } from "../Svg";

const FixedContainer = styled.div`
  position: fixed;
  right: 30px;
  bottom: calc(115px + env(safe-area-inset-bottom));
`;

const ButtonScrollToTop = styled(Button)`
  width: 40px !important;
  height: 40px !important;
  padding: 0px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border: 2px solid ${({ theme }) => theme.colors.colorBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  svg {
    margin: 0px 0px 0px 1px;
  }
`;

const ScrollToTopButtonV2 = () => {
  const [visible, setVisible] = useState(false);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 500) {
        setVisible(true);
      } else if (scrolled <= 500) {
        setVisible(false);
      }
    };

    const throttledToggleVisible = throttle(toggleVisible, 200);

    window.addEventListener("scroll", throttledToggleVisible);

    return () => window.removeEventListener("scroll", throttledToggleVisible);
  }, []);

  return (
    <FixedContainer style={{ display: visible ? "inline" : "none" }}>
      <ButtonScrollToTop endIcon={<ArrowUpIcon />} onClick={scrollToTop} />
    </FixedContainer>
  );
};

export default ScrollToTopButtonV2;
