import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M21.681,19.658c.469,.937,.419,2.028-.131,2.919-.551,.891-1.505,1.423-2.553,1.423H5.003c-1.048,0-2.002-.532-2.553-1.423-.55-.891-.6-1.982-.131-2.919l2.724-5.447c.682-1.364,2.053-2.211,3.578-2.211h6.758c1.525,0,2.896,.847,3.578,2.211l2.724,5.447ZM3.256,13.316c1.022-2.045,3.079-3.316,5.367-3.316h6.758c2.288,0,4.345,1.271,5.367,3.317l1.252,2.503V5c0-2.757-2.243-5-5-5H7C4.243,0,2,2.243,2,5V15.828l1.256-2.512Z"
      />
    </Svg>
  );
};

export default Icon;
