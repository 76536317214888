import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M13 3v3.056a8.923 8.923 0 0 0-2 0V3L9.707 4.293a.999.999 0 1 1-1.414-1.414L10.586.586a2.001 2.001 0 0 1 2.828 0l2.293 2.293a.999.999 0 1 1-1.414 1.414L13 3Zm6.029 17.614A8.953 8.953 0 0 0 21 15c0-4.624-3.506-8.445-8-8.944v8.53l6.029 6.028ZM3 15c0 4.963 4.038 9 9 9a8.959 8.959 0 0 0 5.615-1.972L11.586 16A2.01 2.01 0 0 1 11 14.586v-8.53c-4.494.499-8 4.32-8 8.944Z" />
    </Svg>
  );
};

export default Icon;
