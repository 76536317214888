import { ModalProvider, light, UIKitProvider, Modal } from '@pancakeswap/uikit'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { LanguageProvider } from '@pancakeswap/localization'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { fetchStatusMiddleware } from 'hooks/useSWRContract'
import { Store } from '@reduxjs/toolkit'
import { ThemeProvider as NextThemeProvider, useTheme as useNextTheme } from 'next-themes'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from 'utils/wagmi'
import { HistoryManagerProvider } from 'contexts/HistoryContext'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { style } from 'styled-system'
import Link from 'next/link'

export const WrapModalEvent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(10px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  & > div {
    transform: translateY(-200%);
    transition: 0.4s all;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
    & > div {
      transform: translateY(0%);
      transition: 0.4s all;
    }
  }
`

export const ModalEvent = styled.div`
  width: fit-content;
  height: fit-content;
  position: absolute;
  @media screen and (max-width: 767px) {
    max-width : calc(100% - 30px);
  }
`

export const ImageEvent = styled.img`
  width: 500px;
  height: auto;
  object-fit: contain;
  border-radius: 5px;
  @media screen and (max-width: 767px) {
    width : 100%;
  }
`

export const Line = styled.div`
  width: 18px;
  position: absolute;
  height: 2px;
  background: #fff;
  transform: rotate(45deg);
  z-index: 1;
  &:nth-child(2) {
    height: 3px;
    transform: rotate(-45deg) translate(0px, 0.75px);
    border-bottom: 1.5px solid #000;
    z-index: 2;
  }
`

export const WrapButtonClose = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  border: 1px solid #666;
  width: fit-content;
  background: #000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  text-transform: uppercase;
  font-family: SUISSE_BOLD;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: 0.2s all;
  &:hover {
    background: #111;
    transition: 0.2s all;
  }
`

// Create a client
const queryClient = new QueryClient()

const StyledUIKitProvider: React.FC<React.PropsWithChildren> = ({ children, ...props }) => {
  const { resolvedTheme } = useNextTheme()
  const [popupEvent, setPopupEvent] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupEvent(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  const onClosePopupEvent = () => {
    setPopupEvent(false)
  }

  return (
    <UIKitProvider theme={resolvedTheme === 'dark' ? light : light} {...props}>
      {children}
      <WrapModalEvent className={`${popupEvent ? 'active' : ''}`} onClick={onClosePopupEvent}>
        <ModalEvent>
          <Link href="/launchpad">
            <ImageEvent src="/images/bff-event.jpg" />
          </Link>
          <WrapButtonClose>
            <Line />
            <Line />
          </WrapButtonClose>
        </ModalEvent>
      </WrapModalEvent>
    </UIKitProvider>
  )
}

const Providers: React.FC<React.PropsWithChildren<{ store: Store; children: React.ReactNode }>> = ({
  children,
  store,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig config={wagmiConfig}>
        <Provider store={store}>
          <NextThemeProvider>
            <StyledUIKitProvider>
              <LanguageProvider>
                <SWRConfig
                  value={{
                    use: [fetchStatusMiddleware],
                  }}
                >
                  <HistoryManagerProvider>
                    <ModalProvider>{children}</ModalProvider>
                  </HistoryManagerProvider>
                </SWRConfig>
              </LanguageProvider>
            </StyledUIKitProvider>
          </NextThemeProvider>
        </Provider>
      </WagmiConfig>
    </QueryClientProvider>
  )
}

export default Providers
