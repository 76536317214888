import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <Svg viewBox="0 0 24 24" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M15.213,1.177a4.947,4.947,0,0,0-6.426,0C2.706,6.231-2.63,13.491,4.222,20.778a11.052,11.052,0,0,0,15.556,0C26.636,13.442,21.3,6.257,15.213,1.177ZM8,10a1,1,0,0,1,2,0A1,1,0,0,1,8,10Zm2.832,6.555a1,1,0,1,1-1.664-1.11l4-6a1,1,0,0,1,1.664,1.11ZM15,17a1,1,0,0,1,0-2A1,1,0,0,1,15,17Z"
    />
  </Svg>
);

export default Icon;
