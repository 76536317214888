import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M9.78 20.187c.357.799.28 1.777-.229 2.562a2.742 2.742 0 0 1-2.307 1.252H5.8l-2.553-7h5.337l1.195 3.187ZM7 4H3C1.346 4 0 5.346 0 7v5c0 1.654 1.346 3 3 3h4V4Zm15 3.05V0h-2c0 2.729-3.878 4-6.5 4H9v11h4.5c2.622 0 6.5 1.271 6.5 4h2v-7.05a2.5 2.5 0 0 0 0-4.9Z"
        opacity="1"
      />
    </Svg>
  );
};

export default Icon;
