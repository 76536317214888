import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16 0H8C5.243 0 3 2.243 3 5v14c0 2.757 2.243 5 5 5h8c2.757 0 5-2.243 5-5V5c0-2.757-2.243-5-5-5Zm-1 12.714-2.377 3.396a.76.76 0 0 1-1.247 0l-2.377-3.396c-.3-.429-.3-.999 0-1.427l2.377-3.396a.76.76 0 0 1 1.247 0L15 11.287c.3.429.3.999 0 1.427Z" />
    </Svg>
  );
};

export default Icon;
