import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | BFFSWAP',
  defaultTitle: 'BFFSWAP',
  description: 'Trade anything. No registration, no hassle!',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@BFFSWAP',
    site: '@BFFSWAP',
  },
  openGraph: {
    title: 'BFFSWAP',
    description: 'Trade anything. No registration, no hassle!',
    images: [{ url: 'https://bffswap.finance/logo.png' }],
  },
}
