import styled from "styled-components";
import Link from "next/link";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";

export const StyledFooter = styled(Flex)`
  background: ${darkColors.backgroundAlt};
`;

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 0px;
  }
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &:first-child {
    color: ${darkColors.secondary};
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const StyledToolsContainer = styled(Flex)`
  border-color: ${darkColors.cardBorder};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 24px 0;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledSocialLinks = styled(SocialLinks)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledText = styled.span`
  color: ${darkColors.text};
`;

export const BlockInfoWeb = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  @media screen and (max-width: 1199px) {
    position: fixed;
    bottom: 45px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 0px;
    width: 100%;
    background: ${({ theme }) => theme.colors.backgroundElement};
    padding: 7.5px 10px;
    margin: auto;
    border-bottom: 1px solid ${({ theme }) => theme.colors.colorBorder};
    border-top: 1px solid ${({ theme }) => theme.colors.colorBorder};
  }
`;

export const BlockPriceToken = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: fit-content;
  border-radius: 8px;
  margin: 0px 0px 0px 10px;
  position: relative;
  @media screen and (max-width: 1199px) {
    width: fit-content;
    height: 40px;
    margin: 0px;
    border-radius: 10px;
  }
`;

export const BlockSocial = styled.div`
  width: calc(100% - 20px);
  margin: 10px 0px 10px 10px;
  background: #000;
  border-radius: 10px;
  padding: 14px 12.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  background: ${({ theme }) => theme.colors.gradientPrimary};
  @media screen and (max-width: 1199px) {
    height: 40px;
    width: 150px;
    padding: 0px 12.5px;
    margin: 0px 0px 0px 10px;
    justify-content: center;
    border-radius: 10px;
  }
`;

export const TitleSocial = styled.div`
  color: #fff;
  text-align: center;
  font-family: SUISSE_BOLD;
  font-size: 1.2rem;
  line-height: calc(1.2rem * 1.25);
`;

export const WrapDocs = styled(Link)`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.background};
  padding: 10px;
  height: 40px;
  border-radius: 10px;
`;
export const ImageDocs = styled.img`
  width: 22px;
  height: 22px;
`;
export const TextReferral = styled.div`
  color: ${({ theme }) => theme.colors.color};
  font-size: 1.4rem;
  line-height: calc(1.4rem * 1.25);
  margin: 0px 0px 0px 10px;
`;

export const ImageReferral = styled.img`
  width: 30px;
  height: 30px;
  border-radius : 10px 50% 50% 10px;
  object-fit : contain;
`;

export const WrapReferral = styled(Link)`
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 5px;
  padding: 10px 12.5px;
  height: 40px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
`;
