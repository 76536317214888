import styled from "styled-components";
import { StyledMenuItemProps } from "./types";

export const StyledMenuItemContainer = styled.div<StyledMenuItemProps>`
  position: relative;
  width: 100%;
  padding: 0px 10px;
  @media screen and (max-width: 1199px) {
    padding: 0px;
    &:last-child {
      order: 5;
    }
  }
`;

const StyledMenuItem = styled.a<StyledMenuItemProps>`
  @media screen and (max-width: 1199px) {
    padding: 0px;
    margin: 0px;
    background: transparent !important;
  }
  width: 100%;
  padding: 10px 10px 10px 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.4s all;
  margin: 0px 0px 7.5px 0px;
  background: ${({ theme, $isActive }) => ($isActive ? theme.colors.backgroundActiveMenu : "transparent")};
  & > div {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    @media screen and (max-width: 1199px) {
      justify-content: center;
      flex-direction: column-reverse;
    }
  }
  &:hover {
    filter: brightness(0.9);
  }
  p {
    margin: 0px 0px 0px 17.5px;
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.color : theme.colors.textDisabled)};
    @media screen and (max-width: 1199px) {
      font-size: 1rem;
      line-height: calc(1rem * 1.25);
    }
  }
  svg {
    width: 16px;
    fill: ${({ theme, $isActive }) => ($isActive ? theme.colors.color : theme.colors.textDisabled)};
  }
`;

export const StyledName = styled.div`
  user-select: none;
  p {
    font-family: SUISSE_BOLD;
    @media screen and (max-width: 1199px) {
      margin: 5px 0px -2px 0px;
    }
  }
`;

export default StyledMenuItem;
