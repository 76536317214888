import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="m18.79 13.74-3.77 4.6 2.66 2.81c.38.4.36 1.04-.04 1.42a1.02 1.02 0 0 1-1.42-.04l-5.04-5.32-6.26 6.55 10.58 6.11c.15.09.33.13.5.13s.34-.04.5-.13l10.53-6.08zm8.97-5.11L16.5 2.13c-.31-.17-.69-.17-1 0L4.24 8.63c-.31.18-.5.51-.5.87v12.6l6.71-7.02a1.039 1.039 0 0 1 1.47 0l1.72 1.81 4.34-5.3c.374-.548 1.231-.552 1.61-.01l8.67 10.56V9.5c0-.36-.19-.69-.5-.87zm-15.35 5.53c-1.66 0-3-1.35-3-3 0-1.66 1.34-3 3-3 1.65 0 3 1.34 3 3 0 1.65-1.35 3-3 3z"
      />
    </Svg>
  );
};

export default Icon;
