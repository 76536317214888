import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: SUISSE_REGULAR;
    src: url("/fonts/Suisse-Regular.ttf");
  }
  @font-face {
    font-family: SUISSE_MEDIUM;
    src: url("/fonts/Suisse-Medium.ttf");
  }
  @font-face {
    font-family: SUISSE_BOLD;
    src: url("/fonts/Suisse-Bold.ttf");
  }
  @font-face {
    font-family: SUISSE_BLACK;
    src: url("/fonts/Suisse-Black.ttf");
  }

  * {
    font-family: SUISSE_REGULAR;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    background: ${({ theme }) => theme.colors.background};
    scroll-behavior: smooth;
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background};
    font-family: SUISSE_REGULAR;
    font-size: 1.4rem;
    line-height: 1.75rem;
    color: #000;
    overflow-y : auto;
    overflow-x: hidden;
    img {
      height: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1199px) {
    .wrapContent {
      padding : 80px 0px 150px 0px !important;
    }
    .blockContainer {
      max-width: unset !important;
      min-width: unset !important;
      margin: 0px !important;
      padding: 0px 15px !important;
    }
  }

  .blockContainer {
    max-width: 1300px;
    min-width: 800px;
    margin: 0 auto;
    padding: 0px 15px;
  }

  .wrapContent {
    padding : 0px 0px 0px 200px;
  }

  #__next {
    position: relative;
    z-index: 1;
  }

  #portal-root {
    position: relative;
    z-index: 2;
  }

  @keyframes upDown {
    from {
      transform : translate(10px, -10px) rotate(-10deg);
    }
    to {
      transform : translate(30px, 10px) rotate(0deg) ;
    }
  }

  .SwapWidget_inputContainerVariants_base__1cvvxtwa:focus-within {
    box-shadow :  0px 0px 0px 1px #34b3c3, 0px 0px 0px 4px #34b3c3 !important;
  }

  #token-search-input {
    box-shadow :  0px 0px 0px 1px #34b3c3, 0px 0px 0px 4px #34b3c3 !important;
  }

  @keyframes progressLoad {
    0% {
      left: -50%;
      opacity: 1;
    }
    100% {
        left: 150%;
        opacity: 0;
    }
  }
`

export default GlobalStyle
