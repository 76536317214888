import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import Image from "next/image";
import Flex from "../../../components/Box/Flex";
import { MenuContext } from "../context";

interface Props {
  href: string;
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); }
  50% { transform:  scaleY(0.1); }
`;

// const StyledLink = styled("a")`
//   display: flex;
//   .mobile-icon {
//     width: 32px;
//     ${({ theme }) => theme.mediaQueries.lg} {
//       display: none;
//     }
//   }
//   .desktop-icon {
//     width: 160px;
//     display: none;
//     ${({ theme }) => theme.mediaQueries.lg} {
//       display: block;
//     }
//   }
//   .eye {
//     animation-delay: 20ms;
//   }
//   &:hover {
//     .eye {
//       transform-origin: center 60%;
//       animation-name: ${blink};
//       animation-duration: 350ms;
//       animation-iteration-count: 1;
//     }
//   }
// `;

const BlockLogo = styled("a")`
  position: fixed;
  top: 0px;
  left: calc(200px / 2 - 70px / 2);
  z-index: 999;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60px !important;
    height: 60px !important;
    object-fit: contain;
  }
  @media screen and (max-width: 1199px) {
    position: absolute;
    top: 3px;
    left: 15px;
    width: 50px;
    height: 50px;
  }
`;

const ImageLogo = styled(Image)`
  @media screen and (max-width: 991px) {
    width: 70px;
    height: 70px;
  }
  @media screen and (max-width: 991px) {
    width: 50px;
    height: 50px;
  }
  width: 90px;
  height: 90px;
  object-fit: cover;
`;

const Logo: React.FC<React.PropsWithChildren<Props>> = ({ href }) => {
  const { linkComponent } = useContext(MenuContext);
  const isAbsoluteUrl = href.startsWith("http");

  return (
    <Flex alignItems="center">
      <BlockLogo href={href} as={isAbsoluteUrl ? "a" : linkComponent} aria-label="BFF Swap home page">
        <ImageLogo className="logoImg" src="/logo.png" alt="logo" width="0" height="0" sizes="100vw" priority />
      </BlockLogo>
    </Flex>
  );
};

export default React.memo(Logo);
